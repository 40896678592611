<template>
	<BaseDialog>
		<template #header>
			<h2>View site as user</h2>
		</template>
		<template #activator="{ openDialog }">
			<BaseButton
				class="!h-8 !w-8 !text-white"
				aria-label="magically view as a different user"
				@click="
					openDialog();
					agentSearch1?.autocomplete.searchInputEl?.focus?.();
				"
			>
				<FAIcon icon="wand-magic-sparkles" />
			</BaseButton>
		</template>
		<div class="rounded bg-gray-200 p-4 text-gray-800 dark:bg-gray-900">
			<BaseAlert type="error">
				Making these changes will likely remove some of your permissions temporarily. You
				will need to reload the page to restore them.
			</BaseAlert>
			<BaseStepper
				ref="stepper"
				:step-config="[
					{ name: 'search', hideFooter: true, title: 'Search existing users' },
					{ name: 'update', title: 'Enter and update information' },
				]"
				:show-progress="false"
				non-linear
				class="my-2"
			>
				<template #search.content>
					<div class="rounded-md bg-white p-2 dark:bg-gray-800">
						<AgentSearch
							ref="agentSearch1"
							should-emit
							show-history
							dark
							@choose="handleUserSelect"
						/>
						<ProgressBar
							v-if="loading"
							class="mt-2 w-full"
							full-width
							text="Loading user data..."
						/>
					</div>
				</template>

				<template #update.content>
					<div class="rounded-md bg-white p-2 dark:bg-gray-800">
						<TextFieldInput
							v-model="userID"
							label="User ID"
							placeholder="User ID"
							class="mt-2"
						/>

						<BaseSelect
							id="userType"
							v-model="userType"
							label="User Type"
							:items="[
								{ text: 'Agent', value: 'agent' },
								{ text: 'Agent Team Member', value: 'staff' },
								{ text: 'SF Employee', value: 'corp' },
								{ text: 'Mirus Staff', value: 'mirus' },
							]"
							outlined
							show-label
							class="mt-6"
						/>

						<p class="mb-0 mt-4 font-medium">Agents who have granted access</p>

						<AgentSearch
							:show-history="false"
							:should-emit="true"
							@choose="addAgentPermission"
						/>
						<div class="flex flex-wrap gap-3 pl-1 pt-2">
							<BaseButton
								v-for="agent in staffAgents"
								:key="agent.associate_id"
								dense
								outline
								class="pl-1"
								:color="agent.full_permissions ? 'success' : 'error'"
								:aria-label="`remove agent permission for ${agent.title}`"
								@click="removeAgentPermission(agent)"
							>
								<template #prepend>
									<SFProfileImage
										:width="30"
										:associate_id="agent.associate_id"
										class="mr-2"
									/>
								</template>
								{{ agent.title }}
								<template #append>
									<FAIcon icon="xmark" class="text-xl" />
								</template>
							</BaseButton>
						</div>

						<div class="mt-4 flex flex-wrap items-center gap-2">
							<CheckboxGroup
								v-model:values="permissions"
								name="corp-permissions"
								:options="CORP_PERMISSIONS"
								legend="Corporate Permissions"
							/>
						</div>

						<BaseToggle
							v-model="internal"
							visible-label="Mirus internal permissions"
							class="px-1 py-2"
						/>

						<div
							class="pl-2"
							:class="{
								'text-red-500': !internal,
								'text-orange-500': internal,
							}"
						>
							You will not be able to turn this back on without reloading!
						</div>
					</div>
				</template>
			</BaseStepper>
		</div>
		<template #actions="{ closeDialog }">
			<div class="mt-2 flex items-center justify-end gap-2">
				<BaseButton color="error" @click="closeDialog">Close</BaseButton>
				<BaseButton
					color="gray"
					type="reset"
					@click.prevent="
						handleReset();
						closeDialog();
					"
				>
					Reset Changes
				</BaseButton>
				<BaseButton
					color="primary"
					type="submit"
					@click.prevent="
						handleSubmit();
						closeDialog();
					"
					>View as user</BaseButton
				>
			</div>
		</template>
	</BaseDialog>
</template>

<script setup>
import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';

import { useApi } from '@/composables/useApi';

import useUserStore from '@/stores/user';

import BaseAlert from '@/components/ui/BaseAlert';
import BaseButton from '@/components/ui/BaseButton.vue';
import BaseStepper from '@/components/ui/BaseStepper.vue';
import TextFieldInput from '@/components/ui/TextFieldInput.vue';
import BaseSelect from '@/components/ui/BaseSelect.vue';
import BaseToggle from '@/components/ui/BaseToggle.vue';
import CheckboxGroup from '@/components/ui/CheckboxGroup.vue';
import BaseDialog from '@/components/ui/BaseDialog.vue';

import AgentSearch from '@/components/common/AgentSearch';
import SFProfileImage from '@/components/common/SFProfileImage';
import ProgressBar from '@/components/ui/ProgressBar.vue';

const CORP_PERMISSIONS = [
	{ value: 'calltracking' },
	{ value: 'qlp' },
	{ value: 'queue' },
	{ value: 'm1editor' },
	{ value: 'm1stats' },
	{ value: 'participation' },
	{ value: 'departures' },
];
const router = useRouter();
const userStore = useUserStore();
const { user } = storeToRefs(userStore);

const stepper = ref(null);
const agentSearch1 = ref(null);

const userID = ref(null);
const userType = ref(null);
const internal = ref(true);
const staffAgents = ref([]);
const menus = ref([]);
const permissions = ref(CORP_PERMISSIONS.map(p => p.value));
const loading = ref(false);

function handleReset() {
	user.value = {
		...user.value,
		associate_id: user.value?.user_details?.provider_id,
		user_type: 'mirus',
		staff_agents: [],
		corporate_permissions: CORP_PERMISSIONS.map(p => p.value),
	};
	router.replace({ name: 'index' });
}

function handleSubmit() {
	user.value = {
		...user.value,
		associate_id: userID.value,
		user_type: userType.value,
		internal: internal.value,
		corporate_permissions: permissions.value,
		staff_agents: staffAgents.value.map(({ associate_id }) => associate_id),
	};
	router.replace({ name: 'index' });
}

async function handleUserSelect({ link, associate_id }) {
	const [linkType, id] = link.split('/').filter(Boolean);
	let response;
	switch (linkType) {
		case 'agents':
			userType.value = 'agent';
			break;
		case 'atms':
			userType.value = 'staff';
			loading.value = true;
			response = await useApi(`api/users/staff/${associate_id}/`, {
				message: `Oops, we couldn't retrieve the data for Staff ID ${associate_id}. Please try again later`,
			}).json();
			staffAgents.value = response.data.value?.agents;
			permissions.value = response.data.value?.permissions || [];
			break;
		case 'employees':
			userType.value = 'corp';
			loading.value = true;
			response = await useApi(`api/users/corporate/${id}/`, {
				message: `There was an issue retrieving the data for employee id ${id}. Please try again later.`,
			}).json();
			permissions.value = response.data.value?.permissions || [];
			break;
		default:
			userType.value = 'mirus';
			permissions.value = [
				'calltracking',
				'qlp',
				'queue',
				'm1editor',
				'm1stats',
				'participation',
				'departures',
			];
	}

	loading.value = false;
	userID.value = id;
	internal.value = linkType === 'mirus';
	stepper.value.goToNext();
}
function addAgentPermission({ link, ...rest }) {
	const [type, associate_id] = link.split('/').filter(Boolean);

	staffAgents.value = [
		...staffAgents.value,
		{ link, type, associate_id, ...rest, full_permissions: true },
	];
}
function removeAgentPermission({ associate_id }) {
	staffAgents.value = staffAgents.value.filter(agent => agent.associate_id !== associate_id);
	menus.value[associate_id] = false;
}

defineExpose({
	userID,
	userType,
	internal,
	permissions,
	staffAgents,

	handleSubmit,
	handleReset,
	handleUserSelect,
});
</script>
