<template>
	<fieldset>
		<legend
			class="mb-2 inline text-sm font-semibold uppercase tracking-wider text-gray-500 dark:text-gray-200"
			:class="hideLegend && 'sr-only'"
		>
			{{ legend }}
		</legend>
		<div v-if="bulkSelect" class="flex gap-2 mb-2">
			<BaseButton dense outline @click="selectAll()">Select All</BaseButton>
			<BaseButton dense outline @click="deselectAll()">Deselect All</BaseButton>
		</div>
		<div class="flex" :class="column ? 'flex-col' : 'flex-wrap'">
			<CheckboxInput
				v-for="option in parsedOptions"
				:key="option[itemKey]"
				:model-value="values?.includes(option[itemKey]) ?? false"
				:name="`${name}-${option[itemTextKey] || option[itemValueKey] || option[itemKey]}`"
				:value="option[itemValueKey]"
				:disabled="option.disabled || false"
				:class="dense || 'px-3 py-3'"
				@update:model-value="checked => updateSelectedValues(option[itemKey], checked)"
			>
				{{
					option[itemTextKey] ||
					option[itemValueKey] ||
					option[itemKey] ||
					JSON.stringify(option)
				}}
			</CheckboxInput>
		</div>
		<slot />
	</fieldset>
</template>

<script setup>
import { computed, toRaw } from 'vue';
import CheckboxInput from '@/components/ui/CheckboxInput';
import BaseButton from '@/components/ui/BaseButton';

const emit = defineEmits(['update:values']);

const props = defineProps({
	// model that will be emitted with updates
	values: {
		type: Array,
		required: true,
		validator(value) {
			return value.every(v => typeof v === 'string');
		},
	},

	// full list of possible selections
	options: { type: Array, required: true },
	itemKey: { type: String, default: 'key' },
	itemTextKey: { type: String, default: 'text' },
	itemValueKey: { type: String, default: 'value' },

	name: { type: String, required: true },
	legend: { type: String, required: true },
	dense: { type: Boolean, required: false },
	hideLegend: { type: Boolean, required: false },
	column: { type: Boolean, default: false },
	bulkSelect: { type: Boolean, default: false },
});

function parseOption(option) {
	if (Array.isArray(option)) {
		return { [props.itemKey]: option.join(','), [props.itemValueKey]: option };
	}

	const finalOption = structuredClone(toRaw(option));
	switch (typeof option) {
		case 'object':
			if (!Object.keys(option).includes(props.itemKey)) {
				finalOption[props.itemKey] =
					option.key || option[props.itemValueKey] || JSON.stringify(option);
			}
			if (!Object.keys(option).includes(props.itemValueKey)) {
				finalOption[props.itemValueKey] =
					option[props.itemKey] || option.key || JSON.stringify(option);
			}
			return finalOption;
		case 'string':
		case 'boolean':
		case 'number':
		default:
			return { [props.itemKey]: option, [props.itemValueKey]: option };
	}
}
const parsedOptions = computed(() => {
	return props.options.map(parseOption);
});

function updateSelectedValues(key, checked) {
	const newValues = parsedOptions.value
		.filter(option => {
			if (option[props.itemKey] == key) {
				return checked;
			}
			return props.values.includes(option[props.itemKey]);
		})
		.map(option => option[props.itemKey]);
	emit('update:values', newValues);
}

function selectAll() {
	emit(
		'update:values',
		parsedOptions.value.map(option => option[props.itemKey])
	);
}

function deselectAll() {
	emit('update:values', []);
}

defineExpose({
	updateSelectedValues,
});
</script>
